<template>
  <div style="padding: 1rem;">
    
    <b-overlay :show="isLoadingControlAccessData" rounded class="">
      <b-card>
        <b-row class="mb-1 pl-1 pr-0">
          <b-col>
            <h4 class="mb-1">{{$t('Access to Property', {house: accessData.housingnumber + ' | ' +accessData.resortsname})}}</h4>
          </b-col>
          <b-col>
            <b-button class="float-right" size="sm" variant="warning" :to="{ name: 'my-property' }">
              <feather-icon icon="ArrowLeftIcon" size="16" class="align-middle" style="color: white" />
            </b-button>
          </b-col>
        </b-row>

        <div>
          <!-- Tipo de Accesso -->
          <div class="border rounded p-1 mb-1">
            <h5>{{$t('Access data')}}</h5>
            <b-row>
              <b-col md="3">
                <label> {{ $t('Reason')+'*'}} </label> <br>
                {{ reasonName }}
              </b-col>

              <b-col md="3" class="mb-1">
                <!-- {{accessData.fullDay}} -->
                <strong>{{ accessData.fullDay ? $t("24 hrs. access") : '' }} </strong> <br>
              </b-col>

              <b-col md="12" class="mb-1"/>

              <b-col md="">
                <label> {{ $t('Initial date')}} </label> <br>
                {{accessData.dateIn}}
              </b-col>

              <b-col md="3" class="border-right">
                <label> {{ $t('timeIn')}} </label> <br>
                {{accessData.timeIn}}
              </b-col>

              <b-col md="">
                <label> {{ $t('End date')}} </label> <br>
                {{accessData.dateOut}}
              </b-col>

              <b-col md="3">
                <label> {{ $t('timeOut')}} </label> <br>
                {{accessData.timeOut}}
              </b-col>

              <b-col md="12" class="mt-1">
                <label> {{ $t('Comments')}} </label> <br>
                {{accessData.notes}}
              </b-col>
            </b-row>
          </div>

          <!-- Datos del invitado -->
          <div class="border rounded p-1">
            <h5>{{$t('Guests data')}}</h5>

            <!-- Tabla de Invitados -->
            <div class="mt-1 border-top pt-1">
              <b-table
                v-if="accessData.guestData.length"
                small
                responsive
                :fields="guestsDataFields"
                :items="accessData.guestData"
                sticky-header="150px"
                :busy.sync="isLoadingControlAccessData"
              >
                <template #table-busy>
                  <div class="text-center my-2">
                    <b-spinner class="align-middle" label="Loading..." variant="success"></b-spinner>
                    <strong> {{$t('loading')}}... </strong>
                  </div>
                </template>
              </b-table>
            </div>
          </div>
        </div>
      </b-card>
    </b-overlay>
  </div>
</template>

<script>
import { mapActions, mapState } from "vuex"
import AccessForm from '@/modules/myProperty/components/AccessControl/AccessForm'

export default {
  async created() {
    await this.init()
    const reasons = await this.fetchControlAccessType({membershipId: this.myContracts.contractSelected.membershipid})
    if (reasons) this.reasons = reasons || []
  },
  components: {
    AccessForm,
  },
  data() {
    return {
      isLoadingControlAccessData: false,
      accessData: null,
      guestsDataFields: [
        { key: 'name', label: this.$t('name'), },
        { key: 'lastname', label: this.$t('lastname'), },
        { key: 'email', label: this.$t('email'), },
        { key: 'type', label: this.$t('guestType'), formatter: value=> {return this.typeGuests?.find(guest=> guest.value == value)?.text || value}},
      ],
      typeGuests: [
        {value: "1", text: this.$t('Adult')},
        {value: "2", text: this.$t('Child')}
      ],
      reasons: []
    }
  },
  computed: {
    ...mapState('auth', ['user','myContracts']),
    reasonName(){
      return this.reasons?.find(guest=> guest.id == this.accessData.reason)?.name || ''
    }
  },
  methods: {
    ...mapActions('myProperty', ['fetchControlAccess', 'fetchControlAccessType']),
    async init(){
      const idAccess = this.$route.params.id
			if(this.isANumber(idAccess)){
        this.isLoadingControlAccessData = true
        const accessRegister = await this.fetchControlAccess({idAccess})
        this.accessData = accessRegister
        this.isLoadingControlAccessData = false
      }
    },
    isANumber(string){
      return string ? !isNaN( string ) && string > 0 : false
    },
  },
}
</script>

<style>

</style>